import $ from "jquery";

window.$ = window.jQuery = $;

$(function () {
    $(document).on("dblclick", ".browse-table .browse-entry", initItemViewer);
    $(document).on("click", ".browse-grid .browse-entry", initItemViewer);
    $(document).on("click", ".browse-table .browse-img", initItemViewer);
    $(document).on("click", ".browse-table .browse-expand", initItemViewer);
    $(document).on("click", ".browse-table .btc", initItemViewer);
    $(document).on("click", ".shot__link", initExploreModal);
});

function initItemViewer(e) {
    const el = $(this);
    e.preventDefault();
    clearSelection();

    if (flagViewerNext === 1) {
        console.info("We are using next version of item viewer");
        return;
    }

    if ($(".browse-grid").length === 0) {
        $(".selected").removeClass("selected");
    }

    if (el.hasClass("browse-entry")) {
        // User clicked .browse-entry
        el.addClass("selected");
    } else {
        // User clicked on image
        el.parent().parent().addClass("selected");
    }
    window.vueApp.openItemViewer();
}

function initExploreModal(e) {
    e.preventDefault();
    window.vueApp.showExploreModal($(this).attr("id"));
}

/**
 * Remove cursor selection (occurs on double click)
 */
function clearSelection() {
    if (document.selection && document.selection.empty) {
        document.selection.empty();
    } else if (window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
    }
}
